import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Button, CheckBox, TextField } from '../../../shared/components/index';
import { TERMS_OF_SERVICE } from 'src/shared/constants/constants';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../../shared/constants/routes';
import {
  selectSetPasswordDetail,
  validateUser,
  userSignUp
} from '../service/setPassword.service';
import HelpAndAssistance from 'src/shared/components/help-and-assistance/HelpAndAssistance';
import { setUserData, setUserToken } from 'src/auth/login/service/auth.service';
import { setAccessToken } from 'src/shared/utils/storage';
import { useAuth } from 'src/providers/AuthguardContext';
import { firebaseAnalytics } from 'src/firebaseNotifications/firebase';
import { setUserId } from 'firebase/analytics';

const SetPasswordForm = () => {
  // Constants
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const dispatch = useDispatch<any>();
  const { login } = useAuth();
  const symbol = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const number = /\d/;

  // State Variables
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showHelpandAssistanceDialog, setShowHelpandAssistanceDialog] =
    useState(false);

  // Redux Values
  const { validateTokenDetail, userDetail, loading } = useSelector(
    selectSetPasswordDetail
  );

  //Zod Schema
  const SetPasswordFormSchema = z
    .object({
      email: z
        .string()
        .min(1, { message: 'Email Address is required' })
        .email()
        .max(50, {
          message: `Email should be less than 50 characters`
        }),
      password: z
        .string()
        .min(1, { message: 'New password is required' })
        .min(9, {
          message: t('invalidPasswordErrMsg')
        })
        .max(16, { message: t('invalidPasswordErrMsg') }),
      confirm_password: z
        .string()
        .min(1, { message: 'Re-Type password is required' })
    })
    .refine((data) => number.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => symbol.test(data.password), {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toLowerCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password.toUpperCase() !== data.password, {
      message: t('invalidPasswordErrMsg'),
      path: ['password']
    })
    .refine((data) => data.password === data.confirm_password, {
      message: 'Re-Type password does not match',
      path: ['confirm_password']
    });

  type SetPasswordFormSchemaPayload = z.infer<typeof SetPasswordFormSchema>;

  //Form Variables
  const { control, formState, handleSubmit, setValue } =
    useForm<SetPasswordFormSchemaPayload>({
      resolver: zodResolver(SetPasswordFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  // Use Effects
  useEffect(() => {
    if (token) {
      const payload = {
        token: token
      };
      dispatch(validateUser(payload));
    }
  }, []);

  useEffect(() => {
    if (validateTokenDetail?.email) {
      setValue('email', validateTokenDetail?.email, { shouldValidate: true });
    }
  }, [validateTokenDetail]);

  useEffect(() => {
    if (userDetail?.user?.id) {
      setUserId(firebaseAnalytics, userDetail?.user?.trellis_uid?.toString());
      dispatch(setUserToken(userDetail?.token?.access));
      dispatch(setUserData(userDetail?.user));
      setAccessToken(userDetail?.token?.access);
      login(userDetail?.token?.access);
      routeTo(ROUTES.JOBS);
    }
  }, [userDetail]);

  // Methods
  const onSubmit = (formData: SetPasswordFormSchemaPayload) => {
    const payload = {
      email: formData?.email?.toLowerCase(),
      password: formData?.password
    };
    dispatch(userSignUp(payload));
  };

  const handleTermsAndConditions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAndConditions(event?.target?.checked);
  };

  const navigateToLogin = () => {
    routeTo(ROUTES.LOGIN);
  };

  return (
    <Box sx={{ px: smallDevice ? 3 : 25, py: 3 }}>
      <Box className="flex-column-center w-100">
        <Box sx={{ mt: 8, mb: 2 }} className="text-h2 textalign">
          {t('set_password.jobPortalMessage')}
        </Box>
        <Box sx={{ mb: 10 }} className="text-medium-regular textalign">
          {t('set_password.selectPassword')}
        </Box>
        <Box className="w-100">
          <form>
            <Box className="flex-column-center align-items-start">
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.emailAddress')}
                      variant="outlined"
                      error={Boolean(errors?.email)}
                      disabled={!!token}
                      helperText={errors?.email?.message}
                      required={true}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.password')}
                      type={showPassword ? 'text' : 'password'}
                      onKeyDown={(e) => {
                        if (e?.key === ' ') {
                          e.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                              color={
                                fieldState?.isDirty
                                  ? fieldState?.error
                                    ? 'error'
                                    : 'primary'
                                  : 'default'
                              }
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.password)}
                      helperText={errors?.password?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 5 }} className="w-100">
                <Controller
                  name="confirm_password"
                  defaultValue=""
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('set_password.reTypePassword')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      onKeyDown={(e) => {
                        if (e?.key === ' ') {
                          e.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword((prev) => !prev)
                              }
                              edge="end"
                              color={
                                fieldState?.isDirty
                                  ? fieldState?.error
                                    ? 'error'
                                    : 'primary'
                                  : 'default'
                              }
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant="outlined"
                      required={true}
                      error={Boolean(errors?.confirm_password)}
                      helperText={errors?.confirm_password?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box className="flex-basic-center" sx={{ mb: 8 }}>
              <CheckBox
                sx={{ mb: 5 }}
                checked={termsAndConditions}
                onChange={handleTermsAndConditions}
              />
              <Box>
                <Box>
                  {t('set_password.termsConditions')}{' '}
                  <span
                    className="text-medium-semibold text-primary cursorPointer"
                    onClick={() => window.open(TERMS_OF_SERVICE, '_blank')}
                  >
                    {t('set_password.termsOfService')}
                  </span>
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: 2 }} className="flex-column-center">
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 1 }}
                  width="button-w-280"
                  btnText={t('set_password.createAccount')}
                  disabled={!isValid || !termsAndConditions}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                />
              )}
              <Button
                btnText={t('set_password.alreadyHaveAccount')}
                variant="text"
                onClick={navigateToLogin}
              />
            </Box>
          </form>
          <Box sx={{ mt: 8, mb: 3 }} className="flex-basic-center">
            <Button
              btnText={t('set_password.helpAndAssistance')}
              variant="text"
              width="button-w-280"
              onClick={() => setShowHelpandAssistanceDialog(true)}
            />
          </Box>
        </Box>
      </Box>

      <HelpAndAssistance
        showHelpAndAssistance={showHelpandAssistanceDialog}
        handleClose={() => setShowHelpandAssistanceDialog(false)}
      />
    </Box>
  );
};

export default SetPasswordForm;
