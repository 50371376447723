import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import forgotPasswordEmailLogo from '../../../assets/images/resend_email.svg';
import { Button } from '../../../shared/components/index';
import {
  clearForgotPasswordDetails,
  selectAuthDetail
} from '../service/auth.service';

interface ForgotPasswordSuccessProps {
  resendEmailHandler: () => void;
}

const ForgotPasswordSuccess = ({
  resendEmailHandler
}: ForgotPasswordSuccessProps) => {
  //Constants
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();

  //useEffect
  useEffect(() => {
    dispatch(clearForgotPasswordDetails());
  }, []);

  //Method
  const handleResendEmail = () => {
    resendEmailHandler();
  };

  //HTML
  return (
    <Box sx={{ p: isMobile ? 5 : 7 }} className="flex-column-center">
      <Box sx={{ mb: 5 }}>
        <img src={forgotPasswordEmailLogo} alt="Resend Email" />
      </Box>
      <Box sx={{ mb: 5 }} className="text-h2 text-grey-2 textalign">
        {t('forgetPassword.checkYourEmail')}
      </Box>
      <Box sx={{ mb: 3 }} className="text-medium-regular text-grey-2 textalign">
        {t('forgetPassword.checkYourEmailSubTitle')}
      </Box>
      <Box sx={{ mb: 5 }} className="text-medium-regular text-grey-2 textalign">
        {t('forgetPassword.didntGetEmail')}
      </Box>
      <Box>
        <Button
          btnText={t('forgetPassword.resendEmail')}
          variant="contained"
          width="button-w-280"
          onClick={handleResendEmail}
        />
      </Box>
    </Box>
  );
};

export default ForgotPasswordSuccess;
