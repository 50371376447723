import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { isDesktop, isIOS } from 'react-device-detect';

import onboardingLogo from '../../assets/images/onboarding.svg';
import SetPasswordForm from './components/SetPasswordForm';
import useQuery from 'src/shared/hooks/useQuery';

const SetPassword = () => {
  // Constants
  const query = useQuery();
  const token = query.get('token') || '';

  // Use Effect
  useEffect(() => {
    if (!isDesktop && !isIOS) {
      const url = `trusttap://?token=${token}&screen=verify-email`;
      window.open(url, '_self');
    }
  }, [isDesktop]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <SetPasswordForm />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Box className="flex-column-center onboarding-right-section">
          <Box sx={{ mr: 12, mt: 10 }}>
            <img src={onboardingLogo} width="470" height="470" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SetPassword;
