export const LOGIN = '/';
export const ERROR_PAGE = '/not-found';

export const DASHBOARD = 'dashboard';
export const JOBS = 'jobs';

export const ONBOARDING = 'onboarding';
export const CREATE_ACCOUNT = 'create-account';
export const RESEND_EMAIL = 'resend-email';
export const RESEND_EMAIL_ERROR = 'resend-email-error';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const DELETE_ACCOUNT = 'delete-account';
export const MY_IDENTITY = 'my-identity';
export const MY_RESUME = 'my-resume';
export const DEEP_LINKING = 'deep-links';
